nav {
  position: fixed;
  display: flex;
  flex-direction: row-reverse;
  transform: rotate(-90deg) translate3d(-50%, 0, 0);
  top: 35%;
  left: -1.1rem;
}
nav div {
  display: flex;
  flex-direction: row-reverse;
}
nav a {
  margin-right: 20px;
  text-decoration: none;
  color: #575757;
  transition: color 0.3s;
}
nav a:hover {
  margin-right: 20px;
  text-decoration: none;
  color: #009b93;
}
nav a.active {
  color: #009b93;
}

@media (max-width: 768px) {
  nav {
    top: 80px;
    left: -2.5rem;
  }
}
